import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasError: false
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorThrown(state) {
      state.hasError = true;
    }
  }
});

export const { errorThrown } = errorSlice.actions;

export default errorSlice.reducer;
