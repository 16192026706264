import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_BASE_LAYER,
  REALTIME_VEHICLES_TOGGLED_KEY
} from '#/map/constants';
import { PARK_AND_RIDE_MODE, BASE_LAYER_STORAGE_KEY } from '#/shared/constants';
import { getItem, storeItem } from '#/shared/utils/storage';
import mapConfig from '#/map-config';
import otpUiConfig from '#/otp-ui-config';

const { companies } = otpUiConfig;

const config = {
  ...mapConfig,
  companies
};

const companyLookup = companies.reduce(
  (obj, company) => ({
    ...obj,
    [company.id]: company
  }),
  {}
);

const getDefaultOverlayState = (state = null) => ({
  showTripRequestOverlay: false,
  showTripPlanOverlay: false,
  showStopsOverlay: true,
  showStopViewerOverlay: false,
  showHighlightedStopOverlay: false,
  showLocationViewerOverlay: false,
  showVehicleTrackerOverlay: false,
  showRouteGeoJsonOverlay: false,
  showRealtimeVehicleOverlay: state
    ? state.realtimeVehiclesToggled
    : config.realtimeVehiclesToggled
});

const initialState = {
  // TODO: remove static config data from the Redux state and read it directly
  //  from config files to simplify state and improve app performance
  config,
  hideMapOnMobile: false,
  overlays: getDefaultOverlayState(),
  realtimeVehiclesToggled: getItem(
    REALTIME_VEHICLES_TOGGLED_KEY,
    config.realtimeVehiclesToggled
  ),
  center: [config.initLat, config.initLon],
  baseLayer: getItem(BASE_LAYER_STORAGE_KEY, DEFAULT_BASE_LAYER),
  showLayersButton: true,
  modes: [],
  companies: [],
  routeLayers: [],
  scooterStations: [],
  bikeStations: [],
  zipcarLocations: [],
  parkAndRides: [],
  stops: null,
  recenterRouteOverlay: true,
  realtimeVehiclesLoading: false,

  // set this value to an object with lat & lon values to zoom the map this
  // location via code outside the map container
  zoomToLocation: null,
  zoomLevel: config.initZoom,
  maxMapOffsetMobile: null,

  // the number of pixels that `window` needs to be scrolled on the y-axis for
  // the mobile map be at its min height and not be covered by the content panel
  mapDefaultScrollMobile: null,
  vehicleRoute: null,
  ml_zoomLevel: config.initZoom,
  ml_bounds: null
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    transitTrackerViewOpened(state) {
      state.overlays = {
        ...getDefaultOverlayState(state),
        showHighlightedStopOverlay: true,
        showStopsOverlay: true,
        showStopViewerOverlay: true
      };
    },
    stopDetailViewOpened(state) {
      return {
        ...state,
        recenterRouteOverlay: false,
        overlays: {
          ...getDefaultOverlayState(state),
          showRouteGeoJsonOverlay: true,
          showStopsOverlay: true,
          showStopViewerOverlay: true
        }
      };
    },
    locationDetailViewOpened(state) {
      state.overlays = {
        ...getDefaultOverlayState(state),
        showStopsOverlay: true,
        showHighlightedStopOverlay: true,
        showLocationViewerOverlay: true
      };
    },
    tripPlanViewOpened(state) {
      state.overlays = {
        ...getDefaultOverlayState(state),
        showStopsOverlay: true,
        showRealtimeVehicleOverlay: false,
        showTripPlanOverlay: true
      };
    },
    tripRequestViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: false,
        overlays: {
          ...getDefaultOverlayState(state),
          showStopsOverlay: true,
          showRealtimeVehicleOverlay: false,
          showTripRequestOverlay: true
        }
      };
    },
    routeDetailViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: false,
        recenterRouteOverlay: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showRouteGeoJsonOverlay: true,
          showRealtimeVehicleOverlay: false
        }
      };
    },
    payViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showStopsOverlay: true,
          showRealtimeVehicleOverlay: false,
          showTripPlanOverlay: true
        }
      };
    },
    routeListViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showStopsOverlay: true,
          showStopViewerOverlay: true
        }
      };
    },
    serviceAlertsViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showStopsOverlay: true,
          showStopViewerOverlay: true
        }
      };
    },
    // this is action/reducer has a more generalized name because it's
    // used by multiple views
    simpleStopViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showStopsOverlay: true,
          showStopViewerOverlay: true,
          showRealtimeVehicleOverlay: false
        }
      };
    },
    modeSelectorViewOpened(state) {
      return {
        ...state,
        hideMapOnMobile: true,
        overlays: {
          ...getDefaultOverlayState(state),
          showTripRequestOverlay: true,
          showStopsOverlay: true,
          showRealtimeVehicleOverlay: false
        }
      };
    },
    vehicleDetailViewOpened(state) {
      state.overlays = {
        ...getDefaultOverlayState(state),
        showStopsOverlay: true,
        showHighlightedStopOverlay: true,
        showStopViewerOverlay: true,
        showVehicleTrackerOverlay: true,
        showRealtimeVehicleOverlay: false
      };
    },
    viewClosed(state) {
      return {
        ...state,
        hideMapOnMobile: false,
        mapLayersOpen: false,
        overlays: getDefaultOverlayState(state)
      };
    },
    routeLinkClicked(state) {
      state.recenterRouteOverlay = true;
    },
    baseLayerChanged(state, action) {
      state.baseLayer = action.payload;
    },
    bikeRentalsFetchSuccess(state, action) {
      state.bikeStations = action.payload;
    },
    vehicleRentalsFetchSuccess(state, action) {
      state.scooterStations = action.payload;
    },
    rentalCompanyIncluded(state, action) {
      state.companies.push(action.payload);
      state.modes = [
        // add mode, but ensure the addition isn't a duplicate
        ...new Set(state.modes.concat(companyLookup[action.payload].modes))
      ];
    },
    rentalCompanyExcluded(state, action) {
      state.companies = state.companies.filter(c => c !== action.payload);
      state.modes = state.modes.filter(m =>
        state.companies.some(c => companyLookup[c].modes === m)
      );
    },
    routeLayerIncluded(state, action) {
      state.routeLayers.push(action.payload);
    },
    routeLayerExcluded(state, action) {
      state.routeLayers = state.routeLayers.filter(rl => rl !== action.payload);
    },
    zipcarFetchSuccess(state, action) {
      state.zipcarLocations = action.payload;
    },
    parkAndRideToggled(state) {
      state.modes = state.modes.includes(PARK_AND_RIDE_MODE)
        ? state.modes.filter(m => m !== PARK_AND_RIDE_MODE)
        : state.modes.concat(PARK_AND_RIDE_MODE);
    },
    parkAndRidesFetchSuccess(state, action) {
      state.parkAndRides = action.payload;
    },
    realtimeVehiclesToggled(state) {
      const nextVehiclesToggled = !state.realtimeVehiclesToggled;
      state.realtimeVehiclesToggled = nextVehiclesToggled;
      state.overlays.showRealtimeVehicleOverlay = nextVehiclesToggled;
      storeItem(REALTIME_VEHICLES_TOGGLED_KEY, nextVehiclesToggled);
    },
    setZoomToLocation(state, action) {
      state.zoomToLocation = action.payload;
    },
    clearZoomToLocation(state) {
      state.zoomToLocation = null;
    },
    setMapZoomLevel(state, action) {
      state.zoomLevel = action.payload;
    },
    setMLMapZoomLevel(state, action) {
      state.ml_zoomLevel = action.payload;
    },
    setMLMapBounds(state, action) {
      state.ml_bounds = action.payload;
    },
    mapHeightMobileRangeChange(state, action) {
      const { maxMapOffsetMobile, mapDefaultScrollMobile } = action.payload;

      return {
        ...state,
        maxMapOffsetMobile,
        mapDefaultScrollMobile
      };
    },
    setVehicleRoute(state, action) {
      state.vehicleRoute = action.payload;
    }
  }
});

export const {
  transitTrackerViewOpened,
  stopDetailViewOpened,
  locationDetailViewOpened,
  tripPlanViewOpened,
  tripRequestViewOpened,
  routeDetailViewOpened,
  payViewOpened,
  routeListViewOpened,
  serviceAlertsViewOpened,
  simpleStopViewOpened,
  modeSelectorViewOpened,
  vehicleDetailViewOpened,
  viewClosed,
  routeLinkClicked,
  baseLayerChanged,
  bikeRentalsFetchSuccess,
  vehicleRentalsFetchSuccess,
  rentalCompanyIncluded,
  rentalCompanyExcluded,
  routeLayerIncluded,
  routeLayerExcluded,
  zipcarFetchSuccess,
  parkAndRideToggled,
  parkAndRidesFetchSuccess,
  realtimeVehiclesToggled,
  setZoomToLocation,
  clearZoomToLocation,
  setMapZoomLevel,
  setMLMapZoomLevel,
  mapHeightMobileRangeChange,
  setVehicleRoute,
  setMLMapBounds
} = mapSlice.actions;
export default mapSlice.reducer;
