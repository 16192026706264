import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

function CircleProgressbar({
  duration,
  clockwise,
  frontCircleColor,
  backCircleColor,
  title,
  className
}) {
  return (
    <Styled.SvgWrapper className={className}>
      {title && <title>{title}</title>}
      <Styled.CircleGroup>
        <Styled.BackCircle backCircleColor={backCircleColor} />
        <Styled.FrontCircle
          duration={duration}
          clockwise={clockwise}
          frontCircleColor={frontCircleColor}
        />
      </Styled.CircleGroup>
    </Styled.SvgWrapper>
  );
}

CircleProgressbar.propTypes = {
  /** Uses the interval set VehicleDetailContainer and uses it for stroke-dash animation durations */
  duration: PropTypes.number.isRequired,
  /** If `true` the animation will move clockwise, otherwise counterclockwise */
  clockwise: PropTypes.bool,
  /** Color of foreground circle that peels away duration `duration` */
  frontCircleColor: PropTypes.string,
  /** Color of background circle revealed when front circle peels away */
  backCircleColor: PropTypes.string,
  /** Title attribute added to SVG wrapper DOM element */
  title: PropTypes.string,
  /** Pass-through to identify element that will styled by styled-components */
  className: PropTypes.string
};

CircleProgressbar.defaultProps = {
  clockwise: true,
  frontCircleColor: 'black',
  backCircleColor: 'white',
  title: null,
  className: null
};

export default CircleProgressbar;
