import { STORAGE_PREFIX } from '#/shared/constants';

export function storeItem(key, object, errorCallback = null) {
  try {
    localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(object));
  } catch (e) {
    console.warn(
      'The trimet.org app can not save user preferences at this time. Local Storage may be disabled for this site. Please re-enable this feature for the full user experience.'
    );
    if (errorCallback) errorCallback();
  }
}

export function getItem(key, notFoundValue = null) {
  let retVal = notFoundValue;
  try {
    const itemAsString = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    if (itemAsString === 'false') {
      retVal = false;
    } else if (itemAsString === 'true') {
      retVal = true;
    } else {
      const json = JSON.parse(itemAsString);
      if (json) {
        return json;
      }
    }
  } catch (e) {
    retVal = notFoundValue;
  }
  return retVal;
}

export function removeItem(key) {
  localStorage.removeItem(`${STORAGE_PREFIX}.${key}`);
}
