import { Link } from 'gatsby';
import styled from 'styled-components';

export const ClearGatsbyLink = styled(Link)`
  /* Allow hover only on devices with mouse hover */
  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }

  /* Set the focus */
  &:focus {
    outline: 2px solid ${props => props.theme.focusVisible};
    outline-offset: -2px;
  }

  /* If browser support :focus-visible, undo outline above.*/
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  /*Set the focus-visible.*/
  &:focus-visible {
    outline: 2px solid ${props => props.theme.focusVisible};
  }
`;

export default ClearGatsbyLink;
