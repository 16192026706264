import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from '#/state/store';
import IntlProvider from '#/shared/providers/IntlProvider';

// this is defined outside the gatsby config files because it needs
// to be used in both `gatsby-browser.js` and `gatsby-ssr.js`
const wrapRootElement = ({ element }) => {
  return (
    // Redux approach derived from Gatsby docs:
    // https://www.gatsbyjs.com/docs/adding-redux-store/
    <ReduxProvider store={store}>
      <IntlProvider>{element}</IntlProvider>
    </ReduxProvider>
  );
};

export default wrapRootElement;
