export const isOutsideBounds = ({ lat, lon }, bounds) => {
  const { top, bottom, left, right } = bounds;
  return lat > top || lat < bottom || lon < left || lon > right;
};

export const getLocationOrDefault = (location, locationConfig) =>
  location || {
    lat: locationConfig.initLat,
    lon: locationConfig.initLon
  };

/**
 * Convert a geolocation object into the format used for locations within this
 * application
 *
 * @param {GeolocationPosition|Object} position Can be a GeolocationPosition
 * instance of a JavaScript object with equivalent structure and properties
 * @param {String} label Name of the location, typically derived through reverse
 * geocoding
 * @returns {{lon, lat, name: undefined}|null}
 */
export const positionToLocation = (position, label = null) =>
  position
    ? {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        name: label || undefined
      }
    : null;

/**
 * Convert an object in the format used for locations within this app into a
 * JavaScript object that mirrors the structure and properties of a
 * GeolocationPosition object
 *
 * @param {Object} location
 * @returns {{coords: {latitude, longitude}}|null}
 */
export const locationToPosition = location =>
  location
    ? {
        coords: {
          latitude: location.lat,
          longitude: location.lon
        }
      }
    : null;
