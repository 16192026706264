import { isRejectedWithValue } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import React from 'react';
import SoftToast from '#/components/SoftToast';

const failingEndpoints = new Set([]);
let networkDownTimeoutId = null;
let networkDownToastId = null;
let networkDownToastVisible = false;

// if requests to two distinct end points are failing we assume
// that the user doesn't have a network connection
const isNetworkDown = () => failingEndpoints.size > 1;

const rtkQueryErrorHandler = () => next => action => {
  // TODO: as of this writing arrival requests aren't yet using Redux Toolkit
  //  Query so failures to that endpoint, which is the most used service, will
  //  not be detected. This implementation will not be fully functional until
  //  calls to that service are migrated to RTKQ:
  //  https://trimet.atlassian.net/browse/PUBWEB-1706
  if (isRejectedWithValue(action) && action.payload.status === 'FETCH_ERROR') {
    failingEndpoints.add(action.meta.arg.endpointName);

    // if more than once service is failing notify the user that there's
    // a network error
    if (isNetworkDown() && !networkDownToastVisible) {
      networkDownTimeoutId = setTimeout(() => {
        networkDownToastId = toast.custom(
          t => (
            <SoftToast
              messageId="system-error.no-network-connection"
              type="error"
              toastOptions={t}
              onUserDismiss={() => {
                // if the user manually dismisses message and there's still
                // a network error initiate it again after the timeout delay
                networkDownTimeoutId = setTimeout(() => {
                  networkDownToastVisible = false;
                }, 25000);
              }}
            />
          ),
          { duration: Infinity }
        );
        // wait 5 seconds once two ends points are confirmed to be failing
        // to ensure a meaningful network outage has occurred
      }, 5000);

      networkDownToastVisible = true;
    }
  } else if (
    isNetworkDown() &&
    action.meta?.baseQueryMeta?.response?.status === 200
  ) {
    toast.dismiss(networkDownToastId);
    networkDownToastVisible = false;
    failingEndpoints.clear();
    clearTimeout(networkDownTimeoutId);
  }

  return next(action);
};

export default rtkQueryErrorHandler;
