import React from 'react';
const SvgUrgentHelpCircle = ({ title, ...props }) => (
  <svg
    data-name="urgent help circle"
    width={20}
    height={20}
    viewBox="0 0 61 61"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      data-name="urgent help circle"
      d="M30.5 0A30.5 30.5 0 1061 30.5 30.5 30.5 0 0030.5 0zm-3.316 9.807H33.8v28.826h-6.616zM30.5 51.193a3.942 3.942 0 113.942-3.942 3.945 3.945 0 01-3.942 3.942z"
      fill="#d1441e"
    />
  </svg>
);
export default SvgUrgentHelpCircle;
