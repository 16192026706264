import styled from 'styled-components';

export const ClearLink = styled.a`
  background: transparent;
  color: inherit;
  border-radius: ${props => props.theme.buttonRadius};
  text-align: inherit;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;

  &:focus {
    background-color: transparent;
    outline: 2px solid ${props => props.theme.focusVisible};
    outline-offset: -2px;
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }

  &:active {
    background-color: transparent;
    outline: 0;
  }

  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  .js-focus-visible &.focus-visible {
    outline: 2px solid ${props => props.theme.focusVisible};
  }
`;

export const InverseClearLink = styled(ClearLink)`
  color: ${props => props.theme.inverseFontColor1};

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColorInverted};
    }
  }
`;
