import { toInteger, toString } from 'lodash-es';
import {
  BUS_FX_MODE,
  BUS_MODE,
  STREETCAR_MODE,
  RAIL_MODE,
  LIGHT_RAIL_MODE,
  MAX_SHUTTLE_COLOR,
  DEFAULT_ROUTE_COLOR
} from '#/shared/constants';
import {
  STREETCAR_SUB_TYPE,
  WES_SUB_TYPE,
  MAX_SUB_TYPE,
  SHUTTLE_SUB_TYPE,
  BRT_SUB_TYPE,
  BUS_TYPE,
  AERIAL_TRAM_ROUTE_ID
} from '#/transit-tracker/constants';

const MAX_SHORT_NAME = 'MAX';

export const getColorFromRoute = route =>
  (route.routeSubType === SHUTTLE_SUB_TYPE && MAX_SHUTTLE_COLOR) ||
  (route.routeColor ? `#${route.routeColor}` : DEFAULT_ROUTE_COLOR);

/**
 * TODO: Move the color values stored into the response from the `/vehicles`
 *  service and get rid of this function
 *
 * Get colors for styling vehicles based on the supplied route number
 * @param routeNumber {number} ID for route that vehicle of interest is
 * operating on
 * @return {{routeColor: string, highlightColor: string, badgeColor: string}}
 */
export const routeNumberToVehicleColors = routeNumber => {
  // the otp-ui TransitVehicleOverlay looks colors under these keys on
  // supplied vehicle object and automatically applies them
  const BASE_KEY = 'routeColor';
  const HIGHLIGHT_KEY = 'highlightColor';

  switch (toInteger(routeNumber)) {
    case 90: // MAX Red
      return {
        [BASE_KEY]: '#c4113c',
        [HIGHLIGHT_KEY]: '#ff6b76'
      };
    case 100: // MAX Blue
      return {
        [BASE_KEY]: '#004a98',
        [HIGHLIGHT_KEY]: '#3f9aff'
      };
    case 190: // MAX Yellow
      return {
        [BASE_KEY]: '#fec526',
        [HIGHLIGHT_KEY]: '#ffec6f'
      };
    case 200: // MAX Green
      return {
        [BASE_KEY]: '#00833e',
        [HIGHLIGHT_KEY]: '#5de87a'
      };
    case 290: // MAX Orange
      return {
        [BASE_KEY]: '#d05d15',
        [HIGHLIGHT_KEY]: '#ff9b3a'
      };
    case 203: // WES
      return {
        [BASE_KEY]: '#6e6e6e',
        [HIGHLIGHT_KEY]: '#cccccc'
      };
    case 193: // Streetcar - NS Line
      return {
        [BASE_KEY]: '#8dc63f',
        [HIGHLIGHT_KEY]: '#acf24d'
      };

    case 194: // Streetcar - A Loop
      return {
        [BASE_KEY]: '#d51668',
        [HIGHLIGHT_KEY]: '#ff308a'
      };
    case 195: // Streetcar - B Loop
      return {
        [BASE_KEY]: '#3e0099',
        [HIGHLIGHT_KEY]: '#a15eff'
      };
    case 2: // FX
      return {
        [BASE_KEY]: '#60a511',
        [HIGHLIGHT_KEY]: '#84e80c'
      };
    default:
      return {
        [BASE_KEY]: '#1663b8',
        [HIGHLIGHT_KEY]: '#5cc3ff'
      };
  }
};

/**
 * @param routeNumber {number} ID for the route for which an short name will
 * be returned
 * @param summaryFormat {boolean} If `true` lines that have a color associated
 * with them will return an empty string as their short name
 * @return {string}
 */
export const getShortNameFromRouteNumber = (
  routeNumber,
  summaryFormat = false
) => {
  switch (toInteger(routeNumber)) {
    case 2:
      return 'FX2';
    // MAX lines: Red, Blue, Mall Shuttle, Yellow, Green, Red, Orange
    case 90:
    case 100:
    case 150:
    case 190:
    case 200:
    case 290:
      return summaryFormat ? '' : MAX_SHORT_NAME;
    // WES commuter Rail
    case 203:
      return summaryFormat ? '' : 'WES';
    // Aerial Tram
    case AERIAL_TRAM_ROUTE_ID:
      return 'AT';
    // Streetcar - NS Line
    case 193:
      return 'NS';
    // Streetcar - A Loop
    case 194:
      return 'A';
    // Streetcar - B Loop
    case 195:
      return 'B';
    // Streetcar - S Line
    case 196:
      return 'S';
    default:
      return toString(routeNumber);
  }
};

/**
 * Returns a short name the supplied route, e.g. 'MAX', 'WES', 'CL', 'A', 'B'
 *
 * @param route {Object}
 * @return {string}
 */
export const getShortNameFromRoute = route => {
  return route.routeSubType === SHUTTLE_SUB_TYPE
    ? MAX_SHORT_NAME
    : getShortNameFromRouteNumber(route.id);
};

export const parseRouteVanityName = (id, description) => {
  const idString = `${id.toString()}-`;
  if (description.indexOf(idString) === 0) {
    return description.slice(idString.length);
  }
  return description;
};

export const formatDestinations = directions =>
  // TODO: this will need to be refactored when the `/routeConfig` web
  //  service is localized since its relying on a pattern that will only
  //  be present for English
  directions.map(d => d.desc.replace(/^To\s/, '')).join('/');

/** Derive OTP style mode ID value from route metadata */
export const routeTypeToMode = route => {
  const { routeSubType, type } = route;

  if (routeSubType === BRT_SUB_TYPE) {
    return BUS_FX_MODE;
  }

  if (routeSubType === STREETCAR_SUB_TYPE) {
    return STREETCAR_MODE;
  }

  if (routeSubType === WES_SUB_TYPE) {
    return RAIL_MODE;
  }

  if (routeSubType === MAX_SUB_TYPE) {
    return LIGHT_RAIL_MODE;
  }

  if (type === BUS_TYPE) {
    return BUS_MODE;
  }

  return null;
};

export const mapRoute = r => {
  const routeColor = getColorFromRoute(r);
  const isShuttle = r.routeSubType === SHUTTLE_SUB_TYPE;

  return r
    ? {
        ...r,
        routeColor,
        routeGeometryColor: isShuttle ? DEFAULT_ROUTE_COLOR : routeColor,
        shortName: getShortNameFromRoute(r),
        vanityName: parseRouteVanityName(r.id, r.desc),
        isShuttle
      }
    : null;
};

export const countAlertsByRoute = alerts => {
  const groupedAlerts = {};
  alerts
    .filter(a => a.route)
    .forEach(a => {
      a.route.forEach(r => {
        if (!groupedAlerts[r.id]) {
          groupedAlerts[r.id] = 0;
        }
        groupedAlerts[r.id] += 1;
      });
    });
  return groupedAlerts;
};

/**
 * For use with the lookup returned by `useGetRouteBasicsQuery`
 */
export const routeLookupToSortedList = routeLookup =>
  (routeLookup ? Object.values(routeLookup) : []).sort(
    (a, b) => a.routeSortOrder - b.routeSortOrder
  );
