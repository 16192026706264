import styled from 'styled-components';

export const ClearInput = styled.input`
  font-family: inherit;
  border-width: 0 0 1px 0;
  border-style: solid;
  background-color: transparent;
  border-color: ${props => props.theme.mainColor};
  padding: 0 6px;
  font-size: 1.2em;
  text-overflow: ellipsis;
  color: ${props => props.theme.primaryFontColor};
  border-radius: 0;

  &::placeholder {
    color: ${props => props.theme.secondaryFontColor};
  }

  &:focus {
    outline: 0;
    background-color: transparent;
  }
`;

export default ClearInput;
