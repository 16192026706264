import { trimStart } from 'lodash-es';
import { NODE_ENVS } from '#/shared/constants';

// these map to the three hosted instance of the strings file that exist
// for each language
export const INTL_DOMAIN_PREFIXES = {
  LABS: 'labs',
  BETA: 'beta',
  PROD: ''
};

/**
 * Three hosted versions of the strings files exist per language under three
 * subdomains; the prod instance of the app should use the `<locale>` subdomain,
 * beta should use `beta-<locale>`, everything else should use `labs-<locale>`
 *
 * To initiate the process of getting new strings translated deploy the app to
 * the domain that mirrors the strings file you're using, e.g. for
 * `labs-<locale>` deploy to `labs.trimet.org/home`, this will put in them
 * Smartling queue, and they'll be translated within a minute or two after
 * an admin approves them
 */
// TODO: `locale` should be typed such that it only accepts codes for
//  languages that we support, but the constants where that's defined
//  must be typed first: https://trimet.atlassian.net/browse/PUBWEB-1774
export const getStringsSubdomain = (locale: string): string => {
  const currentSubdomain = window.location.hostname.split('.').at(-3) || '';

  const intlDomainPrefix =
    process.env.NODE_ENV === NODE_ENVS.PROD &&
    Object.values(INTL_DOMAIN_PREFIXES).includes(currentSubdomain)
      ? currentSubdomain
      : INTL_DOMAIN_PREFIXES.LABS;

  return trimStart(`${intlDomainPrefix}-${locale}`, '-');
};
