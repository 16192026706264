import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPathname: null,
  previousPathname: null,

  // if `true` indicates next location change is a move backwards in the
  // history stack
  back: false,
  pathStack: []
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    updatePathStack(state, action) {
      state.pathStack = action.payload;
    },
    navigateBack(state) {
      state.back = true;
    },
    urlPathChanged(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { navigateBack, urlPathChanged, updatePathStack } =
  historySlice.actions;
export default historySlice.reducer;
