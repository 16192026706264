import styled from 'styled-components';

export const TripButton = styled.button`
  margin: 2px 4px;
  background-color: ${props => props.theme.actionColor};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.actionColor};
  color: ${props => props.theme.secondaryBackgroundColor};
  padding: 6px 10px;
  @media (any-hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.inverseButtonColor2};
    }
  }
  &:focus,
  &:active {
    background-color: ${props => props.theme.actionColor};
    outline: 0;
  }

  &:focus-visible {
    outline: 2px solid ${props => props.theme.focusVisible};
  }
`;

export default TripButton;
