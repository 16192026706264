import { parseOtpId } from '#/trip-planner/utils/itinerary';
import { BUS_FX_MODE, BUS_MODE } from '#/shared/constants';
import { getShortNameFromRouteNumber } from '#/transit-tracker/utils/routes';

/**
 * @param routeNumber {number} ID for the route for which an abbreviation will
 * be returned
 * @param summaryFormat {boolean} If `true` select lines that have a color
 * associated with them will return an empty string as their abbreviation
 * @return {string}
 * @note BEWARE! The getShortRouteLabelFrom* functions provide more complete naming, and are probably what you're looking
 * for. This function is passed to OTP in Itinerary, as OTP needs a simpler function for short names. This function
 * will usually give the intended result, but will likely give imperfect output for bus routes like `8a`, which are used
 * internally when schedules are changing soon.
 */
export const imperfectRouteAbbreviator = (
  routeNumber,
  summaryFormat = false
) => {
  return getShortNameFromRouteNumber(routeNumber, summaryFormat);
};

/** return a list of route ids seen in the itinerary (e.g., filter rt vehicles by route) */
export const itineraryToRouteIds = itinerary =>
  itinerary.legs
    .filter(leg => leg.routeId)
    .map(leg => parseOtpId(leg.routeId, true));

/**
 * @param routeMode {string} route mode
 * @param shortName {string} supplied short name for route, which we want to override for non-bus
 * @param routeNumber {string|number} route number
 * @return {string}
 * @note private function to convert to special 'MAX' 'WES' 'CL' 'A' 'B' strings for transitive-js route labeling
 */
const getShortRouteLabel = (routeMode, shortName, routeNumber) => {
  let shortLabel;

  if (routeMode === BUS_MODE || routeMode === BUS_FX_MODE) {
    shortLabel = shortName;
  } else {
    shortLabel = getShortNameFromRouteNumber(
      typeof routeNumber === 'number'
        ? routeNumber.toString()
        : parseOtpId(routeNumber, true)
    );
  }

  return shortLabel;
};

/**
 * @param leg {Object} leg object
 * @return {string}
 * @note will convert leg to special 'MAX' 'WES' 'CL' 'A' 'B' strings for transitive-js route labeling
 */
export const getShortRouteLabelFromLeg = leg => {
  return getShortRouteLabel(leg.mode, leg.routeShortName, leg.routeId);
};
