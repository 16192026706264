// Pelias Geocoder config
// (this format may eventually change to allow other geocoding services)

export default {
  // Pelias API endpoint
  baseUrl: 'https://ws.trimet.org/peliaswrap/v1',
  paths: {
    reverse: 'reverse'
  },
  boundary: {
    rect: {
      minLon: -123.2034,
      maxLon: -122.135,
      minLat: 45.273,
      maxLat: 45.7445
    }
  },
  maxNearbyStops: 6,
  type: 'PELIAS',

  // by default only a subset of layers are included in the search, passing
  // `null` here enables search on all layers
  layers: null
};
