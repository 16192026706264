import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import CloseIcon from '#/shared/components/GeneratedIcons/generic/Close';
import ToasterA11yWrapper from './ToasterA11yWrapper';
import * as Styled from './styled';

function SoftToast({
  messageId,
  messageValues,
  messageValueIdLookup,
  type,
  onUserDismiss,
  toastOptions
}) {
  const { id: toastId, duration, visible } = toastOptions;
  const progressBarDuration =
    duration && duration < Infinity ? duration / 1000 : null;

  const TypeIcon = {
    error: Styled.ErrorIcon,
    info: Styled.InfoIcon
  }[type];

  const onCloseClick = () => {
    toast.dismiss(toastId);
    onUserDismiss();
  };

  const intl = useIntl();
  const coalescedMessageValues = {
    ...messageValues,
    ...Object.entries(messageValueIdLookup).reduce(
      (obj, [valueKey, valueIntlId]) => ({
        ...obj,
        [valueKey]: intl.formatMessage({ id: valueIntlId })
      }),
      {}
    )
  };

  // this is a work around for a bug in `react-hot-toast` where toasts
  // are sometimes not removed from the DOM after `toast.dismiss` is
  // called, testing showed that this is tied to the message being
  // generated dynamically via `react-intl` and it seems related to this:
  // https://github.com/timolins/react-hot-toast/issues/361
  const onToastAnimationEnd = () => {
    if (!visible) toast.remove(toastId);
  };

  return (
    <Styled.SoftToastWrapper
      visible={visible}
      onAnimationEnd={onToastAnimationEnd}
    >
      {TypeIcon && (
        <Styled.IconWrapper>
          <TypeIcon />
        </Styled.IconWrapper>
      )}
      <Styled.TextWrapper>
        <FormattedMessage id={messageId} values={coalescedMessageValues} />
      </Styled.TextWrapper>
      <Styled.CloseButton
        onClick={onCloseClick}
        aria-label={intl.formatMessage({ id: 'alerts.dismiss-global-alert' })}
      >
        {visible && (
          <>
            {progressBarDuration && (
              <Styled.CircleProgressbar
                duration={progressBarDuration}
                clockwise={false}
              />
            )}
            <CloseIcon />
          </>
        )}
      </Styled.CloseButton>
    </Styled.SoftToastWrapper>
  );
}

SoftToast.propTypes = {
  /** ID used to look up message in intl strings file */
  messageId: PropTypes.string.isRequired,
  /** Values to be injected in placeholders in intl string */
  messageValues: PropTypes.shape({}),
  /**
   * Lookup that maps message value keys to their ID in the intl strings file,
   * this allows values to be translated before they are injected into the
   * translated message.  Values generated here are coalesced with the
   * `messageValues` prop, but take precedence where there are common keys
   */
  messageValueIdLookup: PropTypes.shape({}),
  /** Message type, used to determine icon that is displayed */
  type: PropTypes.oneOf(['error', 'info']).isRequired,
  /** Handler fired when the user manually dismisses the toast */
  onUserDismiss: PropTypes.func,
  /**
   * Options that have been assigned to this toast, see these docs for details
   * on the individual items: https://react-hot-toast.com/docs/toaster
   */
  toastOptions: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.number,
    visible: PropTypes.bool
  }).isRequired
};

SoftToast.defaultProps = {
  messageValues: undefined,
  messageValueIdLookup: {},
  onUserDismiss: () => {}
};

export default SoftToast;
export { ToasterA11yWrapper };
