import querystring from 'querystring';
import coreUtils from '@opentripplanner/core-utils';
import {
  ARRIVE_BY_SELECTOR,
  DATE_SELECTOR,
  DEPART_AT_OPTION,
  DEPARTURE_OPTION_SELECTOR,
  MAIN_TRANSIT_MODE,
  MODE_SELECTOR,
  TIME_SELECTOR
} from '#/shared/constants';
import otpUiConfig from '#/otp-ui-config';

const {
  getDefaultQuery: otpUiGetDefaultQuery,
  planParamsToQuery: otpUiPlanParamsToQuery
} = coreUtils.query;

const { isAccessMode, isTransit, isWalk, getTransitModes, hasTransit } =
  coreUtils.itinerary;

/**
 * Derive default trip planner settings from the trip planner config file. This
 * method augments base functionality from `otp-ui` by replacing the generic
 * transit mode value with defined sub modes
 */
export const getDefaultQuery = () => {
  const query = otpUiGetDefaultQuery(otpUiConfig);
  let queryModes = query[MODE_SELECTOR].split(',');

  // The generic 'TRANSIT' mode needs to be rewritten as its sub-modes
  if (queryModes.includes(MAIN_TRANSIT_MODE)) {
    queryModes = queryModes
      .filter(m => !isTransit(m))
      .concat(getTransitModes(otpUiConfig));
  }

  return { ...query, [MODE_SELECTOR]: queryModes.join(',') };
};

/**
 * Convert trip plan querystring parameters into the format of the
 * `currentQuery` object from the redux state
 *
 * @param {string} urlParams Raw url querystring parameters
 */
export const planParamsToQuery = urlParams => {
  const parsedParams = querystring.parse(urlParams);
  const urlQuery = otpUiPlanParamsToQuery(parsedParams);

  // coerce boolean values that were extracted from the url as string back
  // to their native type
  Object.entries(urlQuery).forEach(([k, v]) => {
    let parsedVal = v;

    if (v === 'true') {
      parsedVal = true;
    } else if (v === 'false') {
      parsedVal = false;
    }

    urlQuery[k] = parsedVal;
  });

  // override the default value of "NOW" for `departArrive`, which comes
  // from otp-ui, when `time` and/or `date` are present in the url so
  // those values are loaded correctly when supplied on their own
  if (
    (parsedParams[DATE_SELECTOR] || parsedParams[TIME_SELECTOR]) &&
    !parsedParams[ARRIVE_BY_SELECTOR]
  ) {
    urlQuery[DEPARTURE_OPTION_SELECTOR] = DEPART_AT_OPTION;
  }

  // as of v1.0.4- `otp-ui` infers that "WALK" in conjunction any transit
  // sub-mode indicates that transit-only is selected, the OTP routing engine
  // includes walk across more modes which can lead to an incorrect display
  // on the mode selector, this processing gets that data into otp-ui format
  const { [MODE_SELECTOR]: modesStr } = urlQuery;
  if (modesStr) {
    const modes = modesStr.split(',');

    if (
      hasTransit(modesStr) &&
      modes.some(m => isAccessMode(m) && !isWalk(m))
    ) {
      urlQuery[MODE_SELECTOR] = modes.filter(m => !isWalk(m)).join(',');
    }
  }

  return urlQuery;
};
