import styled from 'styled-components';

export const ClearButton = styled.button`
  background: transparent;
  color: inherit;
  border: 0;
  text-align: inherit;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;

  /* on mobile the hover state is retained after a button is clicked and isn't
  cleared until another element is clicked so hover styling like this only works
  works properly on desktop: https://stackoverflow.com/a/57419798/2167004 */
  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }

  /* We are relying on focus-visible to display tabbed focus states on 
  elements: https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
  */
  &:focus {
    background-color: transparent;
    outline: 2px solid ${props => props.theme.focusVisible};
  }

  &:active {
    background-color: transparent;
    outline: 0;
  }

  /* An outline is added to the button here only when it gains focus by via
  keyboard tabbing (not when clicked).  These class selectors reference the
  "focus-visible" package which is a shim that mimics the :focus-visible pseudo
  selector for browsers that don't have it.  Once all browser versions we
  support add this pseudo selector the package can be dropped from the project
  requirements and :focus-visible can matched directly.
  
  References:
  https://stackoverflow.com/a/45191208/2167004
  https://www.npmjs.com/package/focus-visible
  */
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  .js-focus-visible &.focus-visible {
    outline: 2px solid ${props => props.theme.focusVisible};
  }
`;

export const InverseClearButton = styled(ClearButton)`
  color: ${props => props.theme.inverseFontColor1};

  @media (any-hover: hover) {
    &:hover {
      background-color: ${props => props.theme.hoverColorInverted};
    }
  }
`;
