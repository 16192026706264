import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { utils as vehicleOverlayUtils } from '@opentripplanner/transit-vehicle-overlay';

const api = {
  host: 'https://maps.trimet.org',
  services: {
    vehicleRouteGeometry: {
      path: {
        base: '/ti/index/patterns/trip/TRIMET:',
        suffix: '/geometry/geojson'
      }
    },
    fullRouteGeometry: {
      path: '/wfs',
      defaultQuery: {
        service: 'WFS',
        version: '1.0',
        request: 'GetFeature',
        typeName: 'current:route',
        maxFeatures: 8,
        srsName: 'EPSG:4326',
        outputFormat: 'json'
      }
    }
  }
};

export const mapsApi = createApi({
  reducerPath: 'mapsApi',
  baseQuery: fetchBaseQuery({ baseUrl: api.host }),
  refetchOnReconnect: true,
  endpoints: build => ({
    getVehicleRouteGeometry: build.query({
      query: ({ tripId }) => {
        if (!tripId) return {};

        const { base, suffix } = api.services.vehicleRouteGeometry.path;
        return {
          url: `${base}${tripId}${suffix}`
        };
      },
      keepUnusedDataFor: 60,
      transformResponse: (response, meta, { tripId }) =>
        vehicleOverlayUtils.makePattern(response, tripId)
    }),
    getFullRouteGeometry: build.query({
      query: routeIds => {
        if (!routeIds || routeIds?.length < 1) return {};

        const { path, defaultQuery } = api.services.fullRouteGeometry;
        const routeIdMarkup = routeIds.map(
          rId => `
            <PropertyIsEqualTo>
              <PropertyName>id</PropertyName>
              <Literal>${rId}</Literal>
            </PropertyIsEqualTo>`
        );

        return {
          url: path,
          params: {
            ...defaultQuery,
            FILTER: `
              <Filter>
                <Or>${routeIdMarkup}</Or>
              </Filter>
            `
          }
        };
      },
      keepUnusedDataFor: 3600
    })
  })
});

export const { useGetVehicleRouteGeometryQuery, useGetFullRouteGeometryQuery } =
  mapsApi;
