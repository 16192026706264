import { combineReducers } from '@reduxjs/toolkit';
import { developerApi } from '#/services/developerApi';
import { mapsApi } from '#/services/mapsApi';
import transitTrackerReducer from '#/transit-tracker/slice';
import tripPlannerReducer from '#/trip-planner/slice';
import mapReducer from '#/map/slice';
import userReducer from '#/user/slice';
import historyReducer from '#/shared/slices/history';
import locationReducer from '#/shared/slices/location';
import errorReducer from '#/shared/slices/error';

// the reducers combined here control the initial state of the app on
// reload and is where the app will pull settings cached in local storage
// to rehydrate components to a previous state
const rootReducer = combineReducers({
  [developerApi.reducerPath]: developerApi.reducer,
  [mapsApi.reducerPath]: mapsApi.reducer,
  shared: combineReducers({
    error: errorReducer,
    location: locationReducer,
    history: historyReducer
  }),
  transitTracker: transitTrackerReducer,
  tripPlanner: tripPlannerReducer,
  map: mapReducer,
  user: userReducer
});
export default rootReducer;
