/*
  This is a fragile criteria for getting a vanity name from a sign
  -- we're working a way to avoid doing this client-side
*/
export const parseSignVanityName = (fullSign, routeId) => {
  // TODO: this will need to be refactored when the TriMet web
  //  services are localized since its relying on a pattern that will
  //  only be present for English
  const vanityName = fullSign.split(/\s(?:t|T)o\s/)[1];

  if (vanityName) {
    return vanityName;
  }
  if (fullSign.startsWith('Portland Streetcar ')) {
    return fullSign.split('Portland Streetcar ')[1];
  }
  return (fullSign.split(String(routeId))[1] || fullSign || '').trim();
};

export default parseSignVanityName;
